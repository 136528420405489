import { createModule } from 'vuexok'
import store from '@/store/index'
import { apolloClient } from '@/plugins/apollo/default'
import { logBreadcrumb } from '@/core/logger'
import Sentry from '@/plugins/sentry'
import {
  updateCustomerLoyalty,
  UpdateCustomerLoyaltyMutation,
  UpdateCustomerLoyaltyMutationVariables
} from '@/graphql/default/authentication.graphql'
type State = {
  /**
   * Определяет аутентификацию пользователя
   */
  customerId: string
  /**
   * Номер карты лояльности
   */
  loyaltyCard: string
  /**
   * Количество бонусных баллов
   */
  scores: number | null
  /**
   * Определяет будет ли клиент тратить бонусные баллы
   */
  spendScores: boolean
  /**
   * Количество баллов, списываемых при оплате с частичным использовании баллов
   */
  usedPoints: number
  /**
   * Ошибка сканирования карты
   */
  error: string
}
interface CustomerInterface {
  /**
   * Id покупателя
   */
  readonly customerId: State['customerId']
  /**
   * Количество бонусных баллов
   */
  readonly scores: State['scores']
  /**
   * Определяет будет ли клиент тратить бонусные баллы
   */
  readonly spendScores: State['spendScores']
  /**
   * Количество баллов, которые спишут при оплате
   */
  readonly usedPoints: State['usedPoints']
  /**
   * Ошибка сканирования карты
   */
  readonly error: State['error']
  /**
   * Установить номер карты лояльности
   */
  setCardNumber(code: string): Promise<void>
  /**
   * Потратить бонусные баллы
   */
  useScores(count: number, usedPoints: number): void
  /**
   * Сбрасывает аутентификацию
   */
  reset(): void
}
const tag = 'customerModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'gray' }, ...arg)

const createCustomerModule = () => {
  const stateInit = ():State => ({
    loyaltyCard: '',
    customerId: '',
    error: '',
    scores: 0,
    spendScores: false,
    usedPoints: 0
  })
  const vuexModule = createModule(tag, {
    namespaced: true,
    state: stateInit,
    mutations: {
      setCustomerId(state, id: State['customerId']) {
        log('Записываем ID клиента', id)
        state.customerId = id
      },
      setLoyaltyCard(state, code: State['loyaltyCard']) {
        log('Записываем номер карты лояльности', code)
        state.loyaltyCard = code
      },
      setScores(state, scores: State['scores']) {
        log('Записываем количество бонусных баллов', scores)
        state.scores = scores
      },
      resetAuthentication(state) {
        log('Сбрасываем аутентификацию')
        const emptyState = stateInit()
        Object.assign(state, emptyState)
      },
      spendScores(state, value: State['spendScores']) {
        state.spendScores = value
      },
      setUsedPoints(state, value: State['usedPoints']) {
        state.usedPoints = value
      },
      setError(state, error: State['error']) {
        state.error = error
      }
    },
    actions: {
      async authenticateUser(context) {
        log('Запускаем аутентификацию юзера')
        vuexModule.mutations.setError('')
        vuexModule.mutations.setScores(null)
        const cardCode = context.state.loyaltyCard
        const timeStampStartMutate = Date.now()
        await apolloClient
          .mutate<UpdateCustomerLoyaltyMutation,
            UpdateCustomerLoyaltyMutationVariables>({
              mutation: updateCustomerLoyalty,
              variables: {
                cardCode
              }
            })
          .then(result => {
            const customer = result.data?.updateCustomerLoyalty?.customer
            if (customer?.customerId) {
              vuexModule.mutations.setCustomerId(customer.customerId)
              vuexModule.mutations.setScores(customer.bonuses ?? 0)
            } else {
              vuexModule.mutations.setScores(0)
              vuexModule.mutations.setError('Карта не найдена')
            }
          })
          .finally(() => {
            const timeStampFinishMutate = Date.now()
            Sentry.captureMessage('Мутация updateCustomerLoyalty', {
              tags: {
                start: timeStampStartMutate,
                finish: timeStampFinishMutate,
                lasting: timeStampFinishMutate - timeStampStartMutate
              }
            })
          })
      }
    }
  })
  vuexModule.register(store)

  const customerInterface: CustomerInterface = {
    get customerId() {
      return vuexModule.state.customerId
    },
    get scores() {
      return vuexModule.state.scores
    },
    get spendScores() {
      return vuexModule.state.spendScores
    },
    get usedPoints() {
      return vuexModule.state.usedPoints
    },
    get error() {
      return vuexModule.state.error
    },
    async setCardNumber(code: string) {
      vuexModule.mutations.setLoyaltyCard(code)
      await vuexModule.actions.authenticateUser()
    },
    useScores(count: number, usedPoints: number) {
      vuexModule.mutations.setUsedPoints(usedPoints)
      vuexModule.mutations.setScores(count)
      vuexModule.mutations.spendScores(true)
    },
    reset() {
      vuexModule.mutations.resetAuthentication()
    }
  }

  return customerInterface
}
export const customer: CustomerInterface = createCustomerModule()
